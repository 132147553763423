<template>
<!-- <Affix :offset-top="5" @on-change="change"> -->
    <div class="menu-container">
        <div class="menu-top" 
            v-if="!isPhone"
            @click='onMenuTopClick'>
            <span class='select-subject'>{{title}}</span>
            <Icon :size='22' class='pull-right' color='#ffoverflow: hidden; white-space: nowrap; text-overflow: clip;width:100px;f' type="ios-arrow-right"></Icon>
        </div>
        <div class="menu-box" v-if="typeIdx == 0 && !isPhone">
            <Menu ref='resMenu' class='chapter-item' v-if='menuList.ChildList' width='auto' :accordion='true' 
            :open-names='openGroup' 
            :active-name='activeIdx'
            @on-select='clickMenu'
            @on-open-change='clickMenuSection'>
                <template v-for='item in menuList.ChildList'>
                    <MenuItem v-if='!isValidArray(item.ChildList)' :name="item.Idx">
                        <Icon color='#979797' type="ios-photos-outline"></Icon>
                        <el-tooltip :disabled="item.Name.length > 11 ? false : true" effect="light" placement="top-start">
                            <div slot="content" v-html="item.Name"></div>
                            <span class="ivu-menu-span">
                                {{item.Name}}
                            </span>
                        </el-tooltip>
                    </MenuItem>
                    <Submenu v-if='isValidArray(item.ChildList)' :name="item.Idx">
                        <template slot="title">
                            <Icon color='#979797' type="ios-photos-outline"></Icon>
                            <el-tooltip :disabled="item.Name.length > 11 ? false : true" effect="light" placement="top-start">
                                <div slot="content" v-html="item.Name"></div>
                                <span class="ivu-menu-span">
                                        {{item.Name}}
                                </span>
                            </el-tooltip>
                        </template>
                        <template v-for='itemTwo in item.ChildList'>
                            <MenuItem v-if='!isValidArray(itemTwo.ChildList)' :name="itemTwo.Idx">
                                <el-tooltip :disabled="itemTwo.Name.length > 10 ? false : true" effect="light" placement="top-start">
                                    <div slot="content" v-html="itemTwo.Name"></div>
                                    <span class="ivu-menu-span">{{itemTwo.Name}}</span>
                                </el-tooltip>
                            </MenuItem>
                            <Submenu v-if='isValidArray(itemTwo.ChildList)' :name="itemTwo.Idx">
                                <template slot="title">
                                    <el-tooltip :disabled="itemTwo.Name.length > 10 ? false : true" effect="light" placement="top-start">
                                        <div slot="content" v-html="itemTwo.Name"></div>
                                        <span class="ivu-menu-span">{{itemTwo.Name}}</span>
                                    </el-tooltip>
                                </template>
                                <template v-for='itemThree in itemTwo.ChildList'>
                                    <MenuItem :title='itemThree.Name' :name="itemThree.Idx">
                                        <el-tooltip :disabled="itemThree.Name.length > 9 ? false : true" effect="light" placement="top-start">
                                            <div slot="content" v-html="itemThree.Name"></div>
                                            <span class="ivu-menu-span">{{itemThree.Name}}</span>
                                        </el-tooltip>
                                    </MenuItem>
                                </template>
                            </Submenu>
                        </template>
                    </Submenu>
                </template>
            </Menu>
            <div v-else class='no-result'></div>
        </div>
        <!-- 点击目录出现的目录相关筛选 -->
        <transition name="slide-fade">
            <div class="menu-top-choice" v-if='menuChoiceStatus'  v-clickoutside="onHideSearchClick" :class="{'phone-catalog':isPhone}">
                <div v-if="isPhone" class="jiaocai-box">
                    <span class="big-tip"></span>
                    <span class="jiaocai-span">{{$route.path.toLowerCase().includes('ztdetail') ? '教辅' : '教材'}}</span>
                    <span class="book-flag-span sanjiao-span" :class="bookFlag ? 'top-san' : 'bottom-san'" @click="bookFlag = !bookFlag"></span>
                </div>
                <div v-if="bookFlag">
                    <div class='choice-title' v-if="$route.path != '/Res/SpecialDetail' && $route.path != '/Res/Tbjx' && $route.path != '/Res/Tbxx' && $route.path != '/Res/ZtDetail'">
                        <span class="choice-item" :class="{'item-active': typeIdx == 0 && loadAccount().zonename == '271 教育集团'}" @click="onChoiceTypeClick(0)">选择教材</span>
                        <span class="choice-item" v-if="loadAccount().zonename == '271 教育集团'" :class="{'item-active': typeIdx == 1}" @click="onChoiceTypeClick(1)">选择知识点</span>
                    </div>
                    <div class="class-line" v-for='(item, index_f) in transitionList' :key='index_f' v-if='transitionList[index_f].ChildList && transitionList[index_f].ChildList.length > 0 && typeIdx == 0'>
                        <span class='class-blod'> {{item.Name | filters}} </span>
                        <span class='filter' :style="{'margin':isPhone ? '2px' :'0px'}"
                            :class='{"menu-active": itemTwo.Prop == "selected"}'
                            @click='clickClass(item.Name, index_c, itemTwo.Name, $event, itemTwo)'
                            v-for='(itemTwo, index_c) in item.ChildList' :key='index_c'>{{itemTwo.Name}}</span>
                    </div>

                    <!-- 关于知识点的筛选 -->
                    <template v-if='typeIdx == 1 && loadAccount().zonename == "271 教育集团"'>
                        <div class="class-line" v-for='(item, idx) in transitionKnowledgeList' :key='idx'>
                            <span class='class-blod'> {{item.Name | filters}} </span>
                            <span class='filter'
                                :class='{"menu-active": itemTwo.Prop == "selected"}'
                                @click='clickClass1(item.Name, index_c, itemTwo.Name, $event, itemTwo.Idx)'
                                v-for='(itemTwo, index_c) in item.ChildList' :key='index_c'>{{itemTwo.Name}}</span>
                        </div>
                    </template>
                    <div class='pull-right' v-if="!isPhone">
                        <Button class='choice-btn' type="warning" @click='menuChoiceStatus = false'>确定</Button>
                    </div>
                </div>
            </div>
        </transition>
        <!-- 手机版 -->
        <div class="menu-box" :class="{'phone-menu-box':$route.path.toLowerCase().includes('tbxx')}" v-if="typeIdx == 0 && isPhone">
            <div class="jiaocai-box catlog-box">
                <span class="big-tip"></span>
                <span class="jiaocai-span">目录</span>
                <span class="book-flag-span sanjiao-span catlog-flag-span" :class="catlogFlag ? 'top-san' : 'bottom-san'" @click="catlogFlag = !catlogFlag"></span>
            </div>            
            <Menu ref='resMenu' class='chapter-item' v-if='menuList.ChildList && catlogFlag' width='auto' :accordion='true' 
            :open-names='openGroup' 
            :active-name='activeIdx'
            @on-select='clickMenu'
            @on-open-change='clickMenuSection'>
                <template v-for='item in menuList.ChildList'>
                    <MenuItem v-if='!isValidArray(item.ChildList)' :name="item.Idx" @click.native="onMenuItemClick(item)">
                        <Icon color='#979797' type="ios-photos-outline"></Icon>
                        <el-tooltip :disabled="item.Name.length > 11 ? false : true" effect="light" placement="top-start">
                            <div slot="content" v-html="item.Name"></div>
                            <span class="ivu-menu-span">
                                {{item.Name}}
                            </span>
                        </el-tooltip>
                    </MenuItem>
                    <Submenu v-if='isValidArray(item.ChildList)' :name="item.Idx">
                        <template slot="title">
                            <Icon color='#979797' type="ios-photos-outline"></Icon>
                            <el-tooltip :disabled="item.Name.length > 11 ? false : true" effect="light" placement="top-start">
                                <div slot="content" v-html="item.Name"></div>
                                <span class="ivu-menu-span">
                                        {{item.Name}}
                                </span>
                            </el-tooltip>
                        </template>
                        <template v-for='itemTwo in item.ChildList'>
                            <MenuItem v-if='!isValidArray(itemTwo.ChildList)' :name="itemTwo.Idx" @click.native="onMenuItemClick(itemTwo)">
                                <el-tooltip :disabled="itemTwo.Name.length > 10 ? false : true" effect="light" placement="top-start">
                                    <div slot="content" v-html="itemTwo.Name"></div>
                                    <span class="ivu-menu-span">{{itemTwo.Name}}</span>
                                </el-tooltip>
                            </MenuItem>
                            <Submenu v-if='isValidArray(itemTwo.ChildList)' :name="itemTwo.Idx">
                                <template slot="title">
                                    <el-tooltip :disabled="itemTwo.Name.length > 10 ? false : true" effect="light" placement="top-start">
                                        <div slot="content" v-html="itemTwo.Name"></div>
                                        <span class="ivu-menu-span">{{itemTwo.Name}}</span>
                                    </el-tooltip>
                                </template>
                                <template v-for='itemThree in itemTwo.ChildList'>
                                    <MenuItem :title='itemThree.Name' :name="itemThree.Idx"  @click.native="onMenuItemClick(itemThree)">
                                        <el-tooltip :disabled="itemThree.Name.length > 9 ? false : true" effect="light" placement="top-start">
                                            <div slot="content" v-html="itemThree.Name"></div>
                                            <span class="ivu-menu-span">{{itemThree.Name}}</span>
                                        </el-tooltip>
                                    </MenuItem>
                                </template>
                            </Submenu>
                        </template>
                    </Submenu>
                </template>
            </Menu>
            <div v-else-if="!isValidArray(menuList.ChildList)" class='no-result'></div>
        </div>
    </div>
<!-- </Affix> -->
</template>

<script>
const clickoutside = {
    bind(el, binding, vnode) {
        function documentHandler(e) {
            if(el.contains(e.target)) {
                return false
            }
            if(binding.expression) {
                binding.value()
            }
        }

        el.__vueClickOutside__ = documentHandler;
        document.addEventListener('click', documentHandler);
    },
    update() {},
    unbind(el, binding) {
        // 解除事件监听
        document.removeEventListener('click', el.__vueClickOutside__);
        delete el.__vueClickOutside__;
    },
}
export default {
    components: {},
    directives: {clickoutside},
    props: {
        menuList: {
            type: Object,
            default: {}
        },
        transitionList: {
            type: Array,
            default: []
        },
        title: {
            type: String,
            default: '请选择教材'
        },
        transitionKnowledgeList: {
            type: Array
        },
        knowledgeMenuList: {
            type: Array
        },
        isPhone:{
            type:Boolean
        }
    },
    filters: {
        filters(val) {
            if (val == 'Book') {
                return '册次'
            } else {
                return val
            }
        }
    },
    data() {
        return {
            menuChoiceStatus: false,
            openGroup:[],
            activeIdx:null,
            openGroup1:[],
            activeIdx1:null,
            typeIdx: 0,
            bookFlag:true,
            catlogFlag:true
        }
    },
    methods: {
        onMenuItemClick(item){
            this.$emit('onMenuItemClick',item);
        },
        // 悬浮菜单的选择
        onMenuTopClick() {
            this.menuChoiceStatus = !this.menuChoiceStatus
            this.$bus.emit('onMenuTopClick')
        },
        onChoiceTypeClick(idx) {
            this.typeIdx = idx
            this.$emit('onTypeClick', idx)
        },
        onHideSearchClick() {
            this.menuChoiceStatus = false
        },
        clickClass(Name_f, index_c, Name, e, clickItem) {
            if(!$(e.target).hasClass('menu-active')){
                this.$emit('chioce-refresh', {
                    Name_f: Name_f,
                    index_c: index_c,
                    Name: Name,
                    clickIdx: clickItem.Idx
                })
            }
        },
        clickClass1(Name_f, index_c, Name, e, idx) {
            if(!$(e.target).hasClass('menu-active')){
                this.$emit('chioce-refresh1', {
                    Name_f: Name_f,
                    index_c: index_c,
                    Name: Name,
                    Idx: idx
                })
            }
        },
        isOuterMenu(Idx,flag){
            this.menuList.ChildList.forEach((k)=>{
                if(k.Idx == Idx){
                    flag.index = 1
                    return
                }else if(this.isValidArray(k.ChildList)){
                    k.ChildList.forEach((l)=>{
                        if(l.Idx == Idx){
                            flag.index = 2
                            return
                        }else if(this.isValidArray(l.ChildList)){
                            l.ChildList.forEach((m)=>{
                                if(m.Idx == Idx){
                                    flag.index = 3
                                    return
                                }
                            })
                        }
                    })
                }
            })
        },
        manualClosingMenu(index){
            let resMenu = this.$refs.resMenu
            if(this.isValid(resMenu)){
                let $child = resMenu.$children
                for(let k in $child){
                    if(index == 1){
                        $child[k].opened = false
                    }else{
                        for(let m in $child[k].$children){
                            $child[k].$children[m].opened = false
                        }
                    }
                }
            }
        },
        clickMenu(Idx) {
            this.$nextTick(()=>{
                let flag = {index: 1}
                this.isOuterMenu(Idx,flag)
                if(flag.index == 1){
                    this.openGroup = []
                    this.manualClosingMenu(1)
                }else if(flag.index == 2){
                    this.openGroup.pop()
                    this.manualClosingMenu(2)
                }else if(flag.index == 3){
                }
                this.activeIdx = Idx
                this.$emit('on-select-menu', Idx)
            })
        },
        clickMenuSection(IdxGroup){
            let selectIdx = null
            this.activeIdx = null
            if(this.isValidArray(IdxGroup)){
                this.openGroup = IdxGroup
                selectIdx = IdxGroup[IdxGroup.length-1]
            }else{
                this.openGroup = []
            }
            this.$emit('on-select-menu', selectIdx)
        },
        openTree(){
            let unitidx = this.getLocalVal('unitList') ? JSON.parse(this.getLocalVal('unitList')) : []
            if(this.isValidArray(this.menuList.ChildList)){
                if(this.isValidArray(unitidx)){
                    let openGroup = []
                    let activeIdx = null
                    let chapterInfoOBJ = {
                        chapter:null,
                        section:null,
                        section2:null
                    }
                    for(let i=0;i<this.menuList.ChildList.length;i++){
                        let temp = this.menuList.ChildList[i]
                        let idx = temp.Idx
                        let name = temp.Name
                        let child = temp.ChildList
                        if(parseInt(idx) == unitidx){
                            chapterInfoOBJ.chapter = name
                            if(this.isValidArray(child)){
                                openGroup = [idx]
                                activeIdx = null
                            }else{
                                activeIdx = idx
                            }
                        }else if(this.isValidArray(child)){
                            for(let j=0;j<child.length;j++){
                                let temp2 = child[j]
                                let idx2 = temp2.Idx
                                let name2 = temp2.Name
                                let child2  = temp2.ChildList
                                if(parseInt(idx2) == unitidx){
                                    chapterInfoOBJ.chapter = name
                                    chapterInfoOBJ.section = name2
                                    if(this.isValidArray(child2)){
                                        openGroup = [idx,idx2]
                                        activeIdx = null
                                    }else{
                                        openGroup = [idx]
                                        activeIdx = idx2
                                    }
                                }else if(this.isValidArray(child2)){
                                    for(let j=0;j<child2.length;j++){
                                        let temp3 = child2[j]
                                        let idx3 = temp3.Idx
                                        let name3 = temp3.Name
                                        if(parseInt(idx3) == unitidx){
                                            chapterInfoOBJ.chapter = name
                                            chapterInfoOBJ.section = name2
                                            chapterInfoOBJ.section2 = name3
                                            openGroup = [idx,idx2]
                                            activeIdx = idx3
                                        }
                                    }
                                }
                            }
                        }
                    }
                    this.$emit('onChapterCrumbsGet',chapterInfoOBJ)
                    this.openGroup = openGroup
                    this.activeIdx = activeIdx
                }else{
                    this.openGroup = []
                    this.activeIdx = null
                    this.manualClosingMenu(1)
                }
            }
        },
        // 点击的模块
        clickMenu1(idx) {
            this.$nextTick(() => {
                this.activeIdx1 = idx
            })
            this.$emit('on-select-menu1', idx)
        },
        clickMenuSection1(arr) {
            let selectIdx = null
            this.activeIdx1 = null
            if(this.isValidArray(arr)){
                this.openGroup1 = arr
                selectIdx = arr[arr.length-1]
            }else{
                this.openGroup1 = []
            }
            this.$emit('on-select-menu1', selectIdx)
        },
        initMenuList() {
            if(this.isValidArray(this.knowledgeMenuList.ChildList)) {
                
            }
        }
    },
    watch:{
        menuList:{
            handler:function(curr) {
                this.openTree()
            },
            deep: true
        },
        // knowledgeMenuList: {
        //     handler(val) {
        //     },
        //     deep: true
        // }
    },
    created(){
        this.menuChoiceStatus = this.isPhone || false;
        this.openTree()
    },
    mounted() {
        this.$bus.on('onBaketDivClick', () => {
            this.menuChoiceStatus = false
        })
    }
}
</script>

<style scoped>
.menu-container /deep/ .ivu-back-top{
    right: 0.4%!important;
}
.menu-box, .menu-box1{
    border-left: 1px solid #D4DFE7;
}
.menu-box1 /deep/ .ivu-menu-submenu-title{
    padding: 20px 10px;
}
.ivu-menu-opened li{
    background: #fff;
}
.choice-btn{
    width: 110px;
    border-radius: 20px;
}
.no-result{
    width: 100%;
    height:500px;
    line-height: 500px;
    background: url('/static/images/res/jy-nodata_sm.png') no-repeat 50% 40%;
    background-color: #fff;
    font-size:23px;
    text-align: center;
}
.ivu-menu-span{
    width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
}
.select-subject{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width:200px;
    display: inline-block;
}
/* 添加知识点筛选 */
.choice-item{
    margin-right: 20px;
    display: inline-block;
    padding: 5px 18px 5px 18px;
    font-size: 14px;
    border-radius: 30px;
    cursor: pointer;
}
.item-active{
    background: #0EBAD7;
    color: #fff;
}
.phone-catalog{
    position: static;
    width: 100%;
    height: auto;
    border: none;
    border-bottom: 20px solid rgb(244,247,250);
}
.big-tip{
    background: #6DC6FF;
    border-radius: 2px;
    width: 4px;
    height: 20px;
    display: inline-block;
    margin-right: 12px;
}
.jiaocai-box{
    margin-top: 10px;
    display: flex;
    align-items: center;
    position: relative;
    height: 25px;
}
.jiaocai-span{
    font-size: 21px;
    color: #4A4A4A;
}
.book-flag-span{
    position: absolute;
    top: 3px;
    right: 5px;
}
.top-san{
    transition: transform .3s;
    transform: rotate(-180deg);     
}
.bottom-san{
    transition: transform .3s;
    transform: rotate(0deg);

}
.sanjiao-span{
	width:0;
    height:0;
	border-right:10px solid transparent;
	border-left:10px solid transparent;
	border-top:10px solid rgb(148,148,148);
    top:13px
}
.catlog-box{
    padding: 14px 20px;
    height: 45px !important;
    border-bottom: 1px solid #E9F8FF;
}
.catlog-flag-span{
    right: 25px;
    top: 13px;
}
.phone-menu-box{
    border-bottom: 20px solid rgb(244,247,250) !important;
}
</style>
