<template>
    <div class="specail-sun-res" :class="{ showNav: $route.query.hidenav != 'true' && $route.query.type != 'Ztxx' }">
        <!-- 资源预览模块 -->
        <new-preview @close='onClickCloseDetails' :details='details' v-if='detailStatus'></new-preview>
        <div class="res-box">
            <div class="sun-new-header">
                <detail-header v-if="menuList" :menuList="menuList" :transitionList="transitionList"
                    @on-click-back="onBackClick" @onMenuChange="onMenuChange" @inputChange="searchInputChange"
                    @contentSort="contentSort"></detail-header>
            </div>
            <div class="content">
                <!-- 列表页 -->
                <res-list-stu v-if="list.length > 2 && loadAccount().role == '学生'" :list='list' @open-shadow='openDetail'
                    @onScrollBottom="onScrollBottom"></res-list-stu>
                <res-list v-if="list.length > 2 && loadAccount().role != '学生'" :list='list' :data='param'
                    :isSunPlatform="true" :isShowOrigin='isShowOrigin' label="special" @on-change-crumb='clickCrumb'
                    @open-shadow='openDetail' @on-change-page='changePage' @on-delete='deleteList' @on-search='search'
                    @deleteCandidateSuccess="deleteCandidateSuccess" @onScrollBottom="onScrollBottom" ref="resList">
                </res-list>
                <img v-if="!list || list.length <= 2" class='no-result-img' src="/static/images/res/no-resource1.png"
                    alt="" />
            </div>
        </div>
    </div>
</template>
    
<script>
import resMenu from '@/components/res/Common/Menu/Menu'
import resList from '@/components/res/Common/List/NewList'
import ResListStu from '@/components/res/Common/List/ResListStu'
import DetailHeader from './DetailHeader'
const NewPreview = resolve => require.ensure([], () => resolve(require('@/components/res/Common/ResPreview/NewPreview')))
export default {
    name: "",
    components: {
        resMenu,
        resList,
        DetailHeader,
        ResListStu,
        NewPreview
    },
    data() {
        return {
            crumbs: '', // 面包屑数据
            menuList: [], // 左侧目录列表
            transitionList: [], // 目录面包屑点击的悬浮框
            list: [], // 资源列表页
            copyDefaultList: [], // 复制的初始的资源列表页
            detailStatus: false,
            editStatus: false,
            details: {},
            pageParam: {
                pageIndex: 1,
                pageSize: 20
            },
            totalPage: null,//资源总页数
            menuIsLeaf: false,//目录是否为叶子节点
            menuIdArr: [],

            resourceDataName: '', // 搜多名称
        };
    },
    watch: {
    },
    methods: {
        // 递增递减内容排序
        // asc 递增； desc 递减； default 默认初始的
        contentSort(type) {
            let list = JSON.parse(JSON.stringify(this.copyDefaultList));
            if (type == 'default') {
                this.list = list;
            } else {
                let othArrInfo = list.splice(0, 2);
                list = list.sort(compareFilenames);
                if (type == 'desc') {
                    list = list.reverse();
                }

                list.unshift(...othArrInfo);

                this.list = list;
                // 排序算法
                function compareFilenames(a, b) {
                    // // 若数字开头的排序
                    // if (/^\d/.test(a.Name) || /^\d/.test(b.Name)) {
                    //     // 提取字符串中的数字部分
                    //     const extractNumber = (str) => {
                    //         const matches = str.match(/\d+/ig);
                    //         // 若有多个则合并成一个
                    //         return matches;
                    //     };

                    //     function deepLoop(arr1, arr2, i = 0) {
                    //         return typeof arr1[i] === 'undefined' || arr1[i] !== arr2[i] ? (arr1[i] || 0) - (arr2[i] || 0) : deepLoop(arr1, arr2, i + 1)
                    //     }

                    //     const numA = extractNumber(a.Name);
                    //     const numB = extractNumber(b.Name);

                    //     // 如果都是纯数字，则比较数字部分的大小
                    //     if (Array.isArray(numA) && Array.isArray(numB)) {
                    //         let sortIdx = deepLoop(numA, numB);
                    //         // console.log('1111', sortIdx, a.Name, b.Name);
                    //         if (sortIdx != 0) return sortIdx > 0 ? 1 : -1;
                    //         else return a.Name.localeCompare(b.Name, "zh-Hans-CN", { numeric: true });
                    //         // else return b.Name > a.Name;
                    //     } else if (Array.isArray(numA) || Array.isArray(numB)) {
                    //         // 数字部分为空或非纯数字时，优先比较数字部分不为空的字符串
                    //         return Array.isArray(numB) ? -1 : 1;
                    //     }
                    // }

                    // 如果数字部分相同，则比较字母部分或汉字部分
                    return a.Name.localeCompare(b.Name, "zh-Hans-CN", { numeric: true });
                    // return a.Nam > b.Name;
                };
            }
        },
        // 搜索框变化
        searchInputChange(val) {
            // console.log('搜索框：：2222',val);
            this.pageParam.pageIndex = 1;
            this.resourceDataName = val;
            this.getList(this.menuIdArr)
        },
        onMenuChange(menuIdArr, nodesData, versionId) {
            this.menuIdArr = menuIdArr
            this.pageParam.pageIndex = 1;
            this.getList(menuIdArr)
            if (nodesData && nodesData.ChildList && nodesData.ChildList.length == 0) {
                this.menuIsLeaf = true
            } else {
                this.menuIsLeaf = false
            }
            this.setUserOperation(menuIdArr, versionId)
        },
        //记录用户操作
        setUserOperation(menuIdArr, versionId) {
            let api = '/api/UserOperationService/UserOperationSet'
            let userOperation = {
                menuIdArr: menuIdArr || undefined,
                versionId: versionId || undefined,
            }
            if (this.isValidArray(menuIdArr[0])) {
                userOperation.menuIdArr = menuIdArr[0]
            }
            let param = {
                "userOperation": userOperation,
                "mark": `tbcp_${this.$route.query.id}`
            }
            this.$http.post(this.getResourceCenterBusinessUrl() + api, param).then(res => {
                if (res.data.status.code == 200) {

                } else {
                    console.log('UserOperationSet Error');
                }
            })
        },
        getList(idList) {
            let sortList = { orderCode: "asc", isRecommendation: "desc" }
            if (this.menuIsLeaf) {
                sortList = { orderCode: "asc" }
            }
            let r1 = {}
            let r2 = {}
            let api = this.getResourceCenterBusinessUrl() + '/api/CourseCenterService/GetCourseCentersList'
            let param = {
                resourceDataName: this.resourceDataName,
                resourceTag: [],
                pageIndex: this.pageParam.pageIndex,
                pageSize: this.pageParam.pageSize,
                sortList: sortList,
                sortType: "asc"
            }
            if (this.isValidArray(idList)) {
                for (var i = 0; i < idList.length; i++) {
                    // param.resourceTag[0][`unitID${i+1}`] = idList[0][i]
                    r1[`unitID${i + 1}`] = idList[i]
                    r2[`unitID${i + 1}`] = idList[i]
                }
            } else {
                // param.resourceTag[0][`unitID1`] = idList[0][0]
                r1[`unitID1`] = idList[0]
                r2[`unitID1`] = idList[0]
            }
            r1.rtid = 2
            r2.rtid = 3
            r1.resourceDataSource = '1'
            r2.resourceDataSource = '1'
            r1['sCRIdx'] = r2['sCRIdx'] = {
                mustOr: [
                    {
                        buildFilterType: "eq",
                        buildFilterVale: 0
                    },
                    {
                        buildFilterType: "eq",
                        buildFilterVale: 1
                    },
                    {
                        buildFilterType: "notexists",
                        buildFilterVale: 1
                    }
                ]
            }
            param.resourceTag.push(r1)
            param.resourceTag.push(r2)
            param.resourceTag.push(Object.assign({}, r1, { resourceDataSource: 2 }))
            param.resourceTag.push(Object.assign({}, r2, { resourceDataSource: 2 }))
            // param.resourceTag[0].rtid = [2,3]
            let that = this
            this.startLoading()
            this.$http.post(api, param).then(res => {
                this.stopLoading()
                if (res.data.data) {
                    let data = res.data.data
                    data = this.toUpperCase(data)
                    for (let i = 0; i < data.ResourceData.length; i++) {
                        data.ResourceData[i] = this.toUpperCase(data.ResourceData[i])
                    }
                    let list = []
                    let total = { Total: data.Total }
                    let value = { Value: data.PageIndex }
                    this.totalPage = data.TotalPage
                    list.push(total, value)
                    data.ResourceData.forEach(element => {
                        let obj = {
                            Name: element.ResourceDataName,
                            Idx: element.ResourceDataId,
                            IsCollected: element.IsCollected,
                            IsCandidated: element.IsCandidated,
                            IsExcellent: element.IsExcellent,
                            Url: element.Url,
                            TypeIdx: element.Rtid,//1=试卷，3=资源
                            Summary: element.Summary
                        }
                        list.push(obj)
                    });
                    if (that.pageParam.pageIndex > 1) {
                        let newList = list.slice(2)
                        that.list = that.list.concat(newList)
                    } else {
                        that.list = list
                    }
                } else {
                    that.list = []
                }

                that.copyDefaultList = JSON.parse(JSON.stringify(that.list));
            })
        },
        deleteCandidateSuccess() {
        },
        selectMenu(Idx) {
            // 这里 重置 menuList 的时候都会触发 selectMenu
            if (Idx != null && Idx != '') {
                this.updateLocal('unitList', JSON.stringify([Idx]))
            } else {
                this.updateLocal('unitList', JSON.stringify([]))
            }
            this.param.index = 1
        },
        // 关于热度  评分   最新 
        clickCrumb(id) {//面包屑
            this.param.order = id
        },
        openDetail(data) {
            this.details = data
            this.detailStatus = true
            this.$store.commit('OPEN_SHADOW')
        },
        deleteList() {
        },
        onScrollBottom() {
            if (this.pageParam.pageIndex == this.totalPage) {

                return
            }
            this.pageParam.pageIndex++
            this.getList(this.menuIdArr)
        },
        changePage(index) { //页码
            this.pageParam.pageIndex = index
            this.getList(this.menuIdArr)
        },
        search(val) { //搜索框
            this.param.keyword = val
            this.param.index = 1
        },
        onBackClick() {
            let hidenav = this.$route.query.hidenav
            let path = '/Res/Tbcp'
            if (this.$route.query.type) {
                path = '/Res/' + this.$route.query.type
            }
            this.$router.push({
                path: path, query: {
                    type: this.$route.query.type,
                    hidenav: hidenav ? hidenav : undefined
                }
            })
        },
        onClickCloseDetails() {//关闭资源预览
            this.detailStatus = false
            this.$store.commit('CLOSE_SHADOW')
        },
    },
    created() {
    },
}
</script>
    
<style lang="less" scoped>
.showNav {
    padding-left: 95px;
}

.crumb-div {
    margin: 0 auto 20px;
}

.crumb-div /deep/ .ivu-breadcrumb-item-separator {
    color: #757575;
}

.crumb-div /deep/ .ivu-breadcrumb {
    display: inline-block;
    vertical-align: middle;
    color: #757575;
}

.crumb-div /deep/ .ivu-breadcrumb>span:last-child {
    font-weight: normal;
    color: #757575;
}

.back-go {
    display: inline-block;
    margin-right: 12px;
    cursor: pointer;
}

.res-box {
    position: relative;
    /* display: flex;
        justify-content: space-between; */
}

.Lecture-Details {
    position: absolute;
    width: 84%;
    height: 100%;
    left: 8%;
    top: 20px;
    z-index: 999;
}

.menu {
    width: 23%;
}

.content {
    position: relative;
    width: 100%;
}

.sun-new-header {
    width: 100%;
}

.no-result-img {
    margin: 80px auto;
    display: block;
    width: 100%;
    max-width: 450px;
}
</style>