<!--
 * @Author: your name
 * @Date: 2021-03-23 14:27:03
 * @LastEditTime: 2021-09-15 15:34:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \sun_highschool\src\components\res\SunNewComponents\DeitailHeader.vue
-->
<template>
  <div class="DeitailHeader">
    <div class="back-box" @click="onClickBack">
      <i class="el-icon-arrow-left"></i>
      {{$route.query.title}}
    </div>
    <div class="menu-box" @click="onClickMenuBox" v-if="'showMenuBox'">
		<!-- <div class="sortBtn" :class="{ desc:!isDesc,clicked:isFirstClick }" @click="contentSort">
			<div class="sortIcon"></div>
			<div>{{ isDesc ? '递减' : '递增' }}</div>
		</div> -->
		<el-select v-model="sortType" class="sortSelect" @change="contentSort">
			<el-option
				v-for="item in sortLists"
				:key="item.value"
				:label="item.label"
				:value="item.value">
			</el-option>
		</el-select>
		<version-cascader
			v-if="isValidArray(versions)"
			:data="versions"
			:versionsValue="versionsValue"
			@change="onClickModel"
		></version-cascader>
		<!-- <el-cascader
			ref="version"
			class="version"
			v-model="versionsValue"
			:options="versions"
			:props="props2"
			@change="onClickModel"
      	>
			<template slot-scope="{ node, data }">
				  <span >{{data.Name}}</span>
		  	</template>  
		</el-cascader> -->
      	<el-cascader
			class="contents"
			v-model="contentsValue"
			:options="contents"
			:props="props1"
			@change="onClickMenu"
			ref="cascaderMenu"
      	>
	  		<template slot-scope="{ node, data }">
				  <span class="contents-cascader" :menu-id="data.Idx">{{data.Name}}</span>
		  	</template>
	  	</el-cascader>

		<el-input placeholder="试卷名称" clearable @keyup.enter.native="onPointSearchClick" v-model="searchValue" class="searchInput">
			<el-button slot="append" icon="el-icon-search" @click="onPointSearchClick"></el-button>
		</el-input>
    </div>
  </div>
</template>
<script>
String.prototype.replaceAll = function(s1,s2){ 
	return this.replace(new RegExp(s1,"gm"),s2)
}
import VersionCascader from './VersionCascader.vue'
export default {
	name: "DeitailHeader",
	props: [],
	components: {VersionCascader},
	data() {
		return {
			contentsValue: [],
			versionsValue: [],
			props1: {
				label: "Name",
				children: "ChildList",
				value: "Idx",
				checkStrictly: true,
				// emitPath:false
				// expandTrigger: "hover",
			},
			props2: {
				label: "Name",
				children: "ChildList",
				value: "Idx",
				// expandTrigger: "hover",
			},
			contents: [],
			versions: [],
			transitionList: [],
			modelList: [],
			refMenuData:[],
			bindedEventNodeList:[],


			searchValue: '', // 搜索名称

			// 递增递减部分
			sortLists:[
				{
					value: 'default',
					label: '默认排序'
				},
				{
					value: 'asc',
					label: '名称递增'
				},
				{
					value: 'desc',
					label: '名称递减'
				},
			],
			sortType: 'default',
			isDesc:false, // 是否为递减
			isFirstClick: false, // 是否有初次点击
		};
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {
		let that = this
		this.getUserOperation().then(res=>{
			this.getTransitionList().then(()=>{
				that.hideCascader()
			})	
		})
	},
	methods: {
		contentSort(type){
			// this.isDesc = !this.isDesc;
			// this.isFirstClick = true;
			// this.$emit('contentSort', this.isDesc ? 'asc' : 'desc');
			this.$emit('contentSort', type);
		},
		// 因为不知道上传参数的影响，所以这里再创建了一个变化方法
		onPointSearchClick(){
			this.sortType = 'default';
			this.$emit("inputChange", this.searchValue);
		},
		onClickMenuBox(){
			let that = this
			this.$nextTick(()=>{
				//解决新生成的el-cascader-node上没有绑定点击事件的问题
				$('.el-cascader-panel:visible').find('.el-cascader-node').each(function(){
					if(!that.bindedEventNodeList.includes($(this).attr('id'))){
						$(this).on('click',function(){
							that.onVersionNodeClick($(this))
							that.bindedEventNodeList.push($(this).attr('id'))
							$(this).attr('has-event','true')
						})
					}
				})
			})
			let $panel = null
			$('.el-cascader-panel').each(function(){
				if($(this)[0].children.length == 4){
					$panel = $(this)
				}
			})
			let $menu = null
			if($panel){
				$menu = $panel.find('.el-cascader-menu').eq(3)
			}else{
				return
			}
			if($menu.find('li').length == 1){
				$menu.hide()
				return
			}
		},
		//每次点击下拉菜单时控制最后一项显示/隐藏
		onVersionNodeClick(e){
			if(e&&e[0]){//避免递归
				let id = e[0].id
				let arr = id.split('-')
				if(arr[3] == '3')return
			}
			let $panel = null
			$('.el-cascader-panel').each(function(){
				if($(this)[0].children.length == 4){
					$panel = $(this)
				}
			})
			let $menu = null
			if($panel){
				$menu = $panel.find('.el-cascader-menu').eq(3)
			}else{
				return
			}
			this.$nextTick(()=>{
				//点击一级菜单时显示二级菜单
				let nodeId = e[0].id
				nodeId = nodeId.split('-')
				if(nodeId[3] == 0){
					$('.el-cascader-menu').css('display','block')
				}
				if($menu.find('li').length ==1){
					$menu.find('li').eq(0).click()
				}
				if($menu.find('li').length > 1){
					$menu.show()
					return
				}
				this.hideCascader()
			})
		},
		onClickModel(e) {
			this.versionsValue = e
			if(this.$route.query.type == 'Tbcp' || this.$route.query.type == 'Jbzy'|| this.$route.query.type == 'Tszt'){
				this.getMenuList(e[3]);
			}else{
				this.getMenuList(e[2]);
			}
			let text = this.getText(1,e)
			//强制刷新input内文字
			$('.version .el-input__inner').val(text)
			this.hideCascader()
		},
		// wyp 2021/9/15 input自适应宽度
		setIptWidth(nth,val){
			if(!nth || !val){
				return;
			}
			let length = val.split('').length;
			let reg = /[\u4E00-\u9FA5]/g;
			// 设置中文的长度
			let chLength = 0;
			if(val.match(reg)){
				chLength=val.match(reg).length;
			}else{
				chLength=0;
			}
			// 设置英文数字长度
			let enLength = length-chLength;
			//设置最小宽度
			let widthPx=204;
			// 当前宽度
			let iptW=(chLength+3)*14+(enLength+2)*8;
			// 设置第二个菜单最大宽度,以免过长换行
			let maxWidth=$(".DeitailHeader").width()-$(".DeitailHeader .back-box").width()-$('.version .el-input__inner').width()-120;
			if(widthPx < iptW && iptW < maxWidth){
				widthPx = iptW +"px";
			}else if(iptW > maxWidth){
				widthPx=maxWidth+"px";
			}else{
				widthPx="204px";
			}
			if(nth==1){
				$('.version .el-input__inner').css("width",widthPx);
				// $('.VersionCascader .title-box').css("width",widthPx);
			}else if(nth==2){
				$('.contents .el-input__inner').css("width",widthPx);
			}
		},
		hasChildList(){
			console.log(11)
		},
		getText(num,e){//获取input框文字
			let version = ''
			let area = ''
			let modal = ''
			//wyp 2021/9/15 添加四级菜单变量,设定input自适应宽度
			let lastTitle = ''
			let arrs = this.versions;
			if(num==2){
				arrs = this.contents;
			}
			arrs.forEach(item=>{
				if(item.Idx == e[0]){
					version = `${item.Name }`
				}
				if(item.ChildList){
					item.ChildList.forEach(item=>{
						if(item.Idx == e[1]){
							area = `/ ${item.Name }`
						}
						if(item.ChildList){
							item.ChildList.forEach(item=>{
								if(item.Idx == e[2]){
									modal = `/ ${item.Name }`
								}
								if(item.ChildList){
									item.ChildList.forEach(item=>{
										if(item.Idx == e[3]){
											lastTitle = `/ ${item.Name }`
										}
									})
								}
							})
						}
					})
				}
			})
			this.setIptWidth(num,version + area + modal + lastTitle);
			return version + area + modal + lastTitle;
		},
		onClickMenu(e) {
			let nodesData = this.$refs['cascaderMenu'].getCheckedNodes()[0].data
			let that = this
			$("[menu-id]").each(function(){//点击文字时也展开下一级
				if(this.attributes['menu-id'].value == e[e.length-1]&&this.parentNode.parentNode.children[2]){
					$(this).closest('.el-cascader-menu').nextAll().show()
					this.parentNode.parentNode.children[2].click()
				}else if(this.attributes['menu-id'].value == e[e.length-1]){
					$(this).closest('.el-cascader-menu').nextAll().hide()
					// wyp 2021/9/16 添加最后菜单点击收起菜单
					that.$refs.cascaderMenu.dropDownVisible = false
				}
			})
			this.sortType = 'default';
			this.$emit("onMenuChange", e,nodesData,this.versionsValue);
			this.getText(2,e)
		},
		getTransitionList() {
			return new Promise((resolve,reject)=>{
				let that = this
				// let api = `/api/SeriesTextBook/GetSeriesTextBook?PeriodID=${this.getSunAccount("PeriodID")}&SubjectID=${this.getSunAccount("SubjectID")}&ZoneID=${62}&CatalogType=3`;
				let api = `/api/SeriesTextBook/SeriesTextBookRevise?SeriesID=${this.$route.query.id}&ZoneId=${sessionStorage['sc_zone']}`
				if(this.$route.query.type == 'Tbcp'||this.$route.query.type == 'Tszt'){
					api = `/api/SeriesTextBook/GetSeriesTextBook?SeriesID=${this.$route.query.id}&PeriodID=${sessionStorage['sc_period']}&SubjectID=${sessionStorage['sc_subject']}&ZoneId=${sessionStorage['sc_zone']}`;
				}
				if (this.$route.query.type == 'Gkfx'||this.$route.query.type == 'Zkfx'||this.$route.query.type == 'Ztxx') {
					api = `/api/SeriesTextBook/SeriesTextBookRevise?SeriesID=${this.$route.query.id}&ZoneId=${sessionStorage['sc_zone']}&PeriodID=${sessionStorage['sc_period']}&SubjectID=${sessionStorage['sc_subject']}`
				}
				if (this.$route.query.type == 'Ztxx') {
					api = `/api/SeriesTextBook/SeriesTextBookRevise?SeriesID=${this.$route.query.id}&ZoneId=${sessionStorage['sc_zone']}&PeriodID=${sessionStorage['sc_period']}&SubjectID=${this.$store.state.subjectIdx}`
				}
				if(this.$route.query.type == 'Jbzy'){
					api = `/api/SeriesTextBook/GetSeriesTextBook?SeriesID=${this.$route.query.id}&PeriodID=${sessionStorage['sc_period']}&SubjectID=${sessionStorage['sc_subject']}&SchoolID=${sessionStorage['sc_school']}&ZoneId=${sessionStorage['sc_zone']}`;
				}
				console.log(this.$route.query.type);
				console.log(api);
				console.log(this.$store.state.subjectIdx);
				this.startLoading();
				this.$http.get(this.getMasterDataCenterUrl() + api).then((res) => {
					this.stopLoading();
					if (this.isValidArray(res.data.data)) {
						let data = res.data.data
						for (let i = 0; i < data.length; i++) {
							let element = data[i]
							element = this.toUpperCase(element)
							for (let j = 0; j < element.ChildList.length; j++) {
								let element1 = element.ChildList[j];
								element1 = this.toUpperCase(element1)
								for (let k = 0; k < element1.ChildList.length; k++) {
									let element2 = element1.ChildList[k];
									element2 = this.toUpperCase(element2)
									if(this.$route.query.type == 'Tbcp' || this.$route.query.type == 'Jbzy'|| this.$route.query.type == 'Tszt'){
										for (let l = 0; l < element2.ChildList.length; l++) {
											let element3 = element2.ChildList[l];
											element3 = this.toUpperCase(element3)
										}
									}
								}
							}
						}
						let list = data;
						list.forEach((item) => {
							item.ChildList.forEach((item1) => {
								item1.ChildList.forEach(item2 => {
									if(this.$route.query.type == 'Tbcp' || this.$route.query.type == 'Jbzy' || this.$route.query.type == 'Tszt'){
										item2.ChildList.forEach(item3 =>{
											item3.ChildList = undefined;
										})
									}else{
										item2.ChildList = undefined;
									}
								});
							});
						});
						this.versions = list;
						if(this.versionsValue.length == 0){
							this.versionsValue.push(list[0].Idx);
							if (list[0].ChildList.length > 0) {
								this.versionsValue.push(list[0].ChildList[0].Idx);
								if(list[0].ChildList[0].ChildList.length>0){
									this.versionsValue.push(list[0].ChildList[0].ChildList[0].Idx);
									if(this.$route.query.type == 'Tbcp' || this.$route.query.type == 'Jbzy' || this.$route.query.type == 'Tszt'){
										if(list[0].ChildList[0].ChildList[0].ChildList.length>0){
											this.versionsValue.push(list[0].ChildList[0].ChildList[0].ChildList[0].Idx);
										}
									}
								}
							}
						}else{
							this.checkId(list)
						}
						this.getMenuList();
						// this.$nextTick(()=>{
						// 	$('.el-cascader-node').on('click',function(){
						// 		that.onClickMenuBox($(this),'true')
						// 	})
						// 	$('.el-cascader-node').attr('has-event','true')
						// })
						resolve()
					}
				});
			})
		},
		//检查记忆的每一级目录id是否存在,不存在则设为第一项
		checkId(list){
			let index1=0,index2=0,index3=0,index4=0
			for (let i=0;i<list.length;i++) {
				if(this.versionsValue[0]==list[i].Idx){
					index1 = i
					break
				}
				if(i == list.length-1)this.versionsValue[0] = list[0].Idx
			}
			for (let i=0;i<list[index1].ChildList.length;i++) {
				if(this.versionsValue[1]==list[index1].ChildList[i].Idx){
					index2 = i
					break
				}
				if(i == list[index1].ChildList.length-1)this.versionsValue[1] = list[index1].ChildList[0].Idx
			}
			for (let i=0;i<list[index1].ChildList[index2].ChildList.length;i++) {
				if(this.versionsValue[2]==list[index1].ChildList[index2].ChildList[i].Idx){
					index3 = i
					break
				}
				if(i == list[index1].ChildList[index2].ChildList.length-1)this.versionsValue[2] = list[index1].ChildList[index2].ChildList[0].Idx
			}
			try {
				if(list[index1].ChildList[index2].ChildList[index3].ChildList) {
					for (let i=0;i<list[index1].ChildList[index2].ChildList[index3].ChildList.length;i++) {
						if(this.versionsValue[3]==list[index1].ChildList[index2].ChildList[index3].ChildList[i].Idx){
							index4 = i
							break
						}
						if(i == list[index1].ChildList[index2].ChildList[index3].ChildList.length-1)this.versionsValue[3] = list[index1].ChildList[index2].ChildList[index3].ChildList[0].Idx
					}
				}
			} catch (error) {
				console.log(error);
			}
		},
		getMenuList(bookId) {
			let that = this
			let id = ''
			if(this.$route.query.type == 'Tbcp' || this.$route.query.type == 'Jbzy'|| this.$route.query.type == 'Tszt'){
				id = bookId || this.versions[0].ChildList[0].ChildList[0].ChildList[0].Idx;
			}else{
				id = bookId || this.versions[0].ChildList[0].ChildList[0].Idx;
			}
			if(that.versionsValue.length == 4){
				//使用数据库记录的id
				id = that.versionsValue[3]
			}
			let api = this.getMasterDataCenterUrl() + "/api/TextBookUnit?BookID=" + id;
			this.startLoading();
			this.$http.get(api).then((res) => {
			this.stopLoading();
			if (this.isValidArray(res.data.data)) {
				let data = res.data.data
				for (let i = 0; i < data.length; i++) {
					data[i] = this.toUpperCase(data[i])
					for (let j = 0; j < data[i].ChildList.length; j++) {
						let element = data[i].ChildList[j];
						element = this.toUpperCase(element)
						for (let k = 0; k < element.ChildList.length; k++) {
							let element2 = element.ChildList[k];
							element2 = this.toUpperCase(element2)
						}
					}
				}
				let str = JSON.stringify(data);
				let newStr = str.replaceAll(/,"ChildList":\[\]/g, "");
				let newList = JSON.parse(newStr);
				this.contents = null
				this.contents = newList;
				let idx = []
				newList.forEach(item=>{
					idx.push(item.Idx)
				})
				let flag = idx.indexOf(that.contentsValue[0])
				if(bookId || flag == -1){
					that.contentsValue = [newList[0].Idx]
				}
				this.sortType = 'default';
				this.$emit("onMenuChange", this.contentsValue,null,this.versionsValue);
			}else{
				this.contents = []
				this.contentsValue = [] 
			}
			this.$nextTick(()=>{
				$('.el-radio').on('click',function(){
					$(this).siblings(".el-icon-arrow-right").trigger("click");
				})
			})
			// wyp 2021/9/15 初始化自适应宽度
			this.getText(1,this.versionsValue)
			this.getText(2,this.contentsValue)
		});
		},
		onClickBack() {
			this.$emit("on-click-back");
		},
		//使用用户操作记录
        getUserOperation(){
            return new Promise((resolve,reject)=>{
				let that = this
                let api = '/api/UserOperationService/UserOperationGet'
                let param = {
                    "mark": `tbcp_${this.$route.query.id}`
                }
                this.$http.post(this.getResourceCenterBusinessUrl()+api,param).then(res=>{
                    if(res.data.status.code == 200&&this.isValidArray(res.data.data)){
                        if(res.data.data[0].UserOperation){
                            let data = res.data.data[0].UserOperation
							that.versionsValue = data.versionId || []
							that.contentsValue = data.menuIdArr || []
                        }
                    }else{
                        console.log('UserOperationGet Error');
                    }
                    resolve()
                })
            })
        },
		//隐藏“无”的卡片
		hideCascader(){
			$('.el-cascader-node__label span').each(function(index,item){
				if($(item).text() == '无'&&$(item).closest('ul').find('li').length==1){
					$(item).closest('.el-cascader-menu').hide()
				}
			})	
			// setTimeout(() => {
			// 	let text = $('.version .el-input__inner').val()
			// 	text = text.replaceAll('无 /','')
			// 	$('.version .el-input__inner').val(text)
			// }, 0);
		},
	},
};
</script>

<style lang='less' scoped>
.sortSelect{
	width: 105px;
	margin-right: 20px;
}
.sortBtn{
	font-size: 14px;
    color: #4A4A4A;
	cursor: pointer;
    padding: 0 10px;
	margin-right: 10px;

	display: flex;
	align-items: center;
	line-height: 1;
	white-space: nowrap;
	user-select: none;

	.sortIcon{
		background-image: url(../../../assets/images/icon_order.png);
		background-size: 80px 40px;
		width: 20px;
		height: 20px;
		margin-right: 3px;
	}

	&.desc{
		.sortIcon{
			background-position-y: -20px;
		}
	}
	&.clicked{
		color: #2DCCC4;
		
		.sortIcon{
			background-position-x: 40px;
		}
	}
}
.searchInput{
	width: 180px;
	margin-left: 20px;
	line-height: 1!important;
}

.DeitailHeader {
	width: 100%;
	background: #ffffff;
	box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
	height: 60px;
	margin-bottom: 10px;
	display: flex;
	justify-content: space-between;
	position: relative;
	div {
		line-height: 60px;
	}
	.back-box {
		// width: 200px;
		font-size: 14px;
		color: #4a4a4a;
		margin-left: 20px;
		cursor: pointer;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space:nowrap;
		// position: absolute;
   	 	// left: 0;
		i {
			transform: scale(1.5);
		}
	}
	.title {
		font-size: 20px;
		color: #757575;
		// position: absolute;
		// left: 50%;
		// top: 50%;
		// margin-top: -30px;
	}
	.menu-box {
		margin-right: 20px;
		min-width:420px;
		display: flex;
		align-items: center;
		.version {
			margin-right: 20px;
		}
		.contents {
		}
	}
}
</style>
<style>
.el-cascader-menu{
	min-width: 100px;
}
.el-cascader-panel .el-radio {
  position: absolute;
  z-index: 10;
  padding: 0 10px;
  width: 132px;
  height: 34px;
  line-height: 34px;
}
.el-cascader-panel .el-radio__input {
  visibility: hidden;
}
.el-cascader-panel .el-input-node__postfix {
  top: 10px;
}
.el-cascader-node__label{
	font-weight: 400 !important;
}
.el-cascader-panel .is-active {
  color: #22B5AD !important;
  /* background: rgb(34, 181, 173, 0.16); */
}
.el-cascader-panel .is-active:hover {
  color: #4a4a4a !important;
  background: rgb(34, 181, 173, 0.16);
}
.el-cascader-node{
	margin: 0 5px;
	border-bottom: 1px solid #EEEEEE;
	padding: 0 30px 0 0px !important;
}
.el-cascader-node.in-checked-path {
  color: #4a4a4a !important;
  background: rgb(34, 181, 173, 0.16);
}
.el-cascader-node.in-active-path {
  color:#22B5AD !important;
  /* background: rgb(34,181,173,0.16); */
}
.el-cascader-node:not(.is-disabled):hover {
  background: rgb(34, 181, 173, 0.16);
  background:rgb(34, 181, 173,0.1);
}
.el-cascader .el-input.is-focus .el-input__inner {
  border-color: #d6d6d6;
}
.el-cascader .el-input .el-input__inner:focus {
  border-color: #d6d6d6;
}
.el-cascader-panel .el-icon-check{
	display: none;
}
.el-icon-arrow-right{
	z-index: 1000 !important;
}
.el-cascader-panel .el-radio{
	width: 100%;
}
</style>
