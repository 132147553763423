import { render, staticRenderFns } from "./DetailHeader.vue?vue&type=template&id=c4bb158c&scoped=true&"
import script from "./DetailHeader.vue?vue&type=script&lang=js&"
export * from "./DetailHeader.vue?vue&type=script&lang=js&"
import style0 from "./DetailHeader.vue?vue&type=style&index=0&id=c4bb158c&lang=less&scoped=true&"
import style1 from "./DetailHeader.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4bb158c",
  null
  
)

export default component.exports