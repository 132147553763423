<template>
    <div class='VersionCascader'>
        <div class="content">
            <div class="title-box" @click="cascaderMenuVisible = !cascaderMenuVisible">
                <span>{{title}}</span>
                <i class="el-icon-arrow-down" :class="{turnIcon:cascaderMenuVisible}"></i>
            </div>
            <div class="cascader-menu-box" v-show="cascaderMenuVisible">
                <i class="el-icon-caret-top"></i>
                <div class="menu menu1" v-if="isValidArray(menu1Data)&&!isMenu1Null">
                    <ul>
                        <li v-for="item1 in menu1Data" :key="item1.Idx" @click="onMenu1Click(item1)" :class="{menuActive:item1.Idx == menu1SelectedIdx}">
                            <span>{{item1.Name}}</span>
                            <i v-if="isValidArray(item1.ChildList)" class="el-icon-arrow-right"></i>
                        </li>
                    </ul>
                </div>
                <div class="menu menu2" v-if="isValidArray(menu2Data)&&!isMenu2Null">
                    <ul>
                        <li v-for="item2 in menu2Data" :key="item2.Idx" @click="onMenu2Click(item2)" :class="{menuActive:item2.Idx == menu2SelectedIdx}">
                            <span>{{item2.Name}}</span>
                            <i v-if="isValidArray(item2.ChildList)" class="el-icon-arrow-right"></i>
                        </li>
                    </ul>
                </div>
                <div class="menu menu3" v-if="isValidArray(menu3Data)&&!isMenu3Null">
                    <ul>
                        <li v-for="item3 in menu3Data" :key="item3.Idx" @click="onMenu3Click(item3)" :class="{menuActive:item3.Idx == menu3SelectedIdx}">
                            <span>{{item3.Name}}</span>
                            <i v-if="isValidArray(item3.ChildList)&&item3.ChildList.length>1" class="el-icon-arrow-right"></i>
                        </li>
                    </ul>
                </div>
                <div class="menu menu4" v-if="isValidArray(menu4Data)&&menu4Data.length>1">
                    <ul>
                        <li v-for="item4 in menu4Data" :key="item4.Idx" @click="onMenu4Click(item4)" :class="{menuActive:item4.Idx == menu4SelectedIdx}">
                            <span>{{item4.Name}}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'VersionCascader',
    props:['data','versionsValue'],
    components:{},
    data(){
        return {
            cascaderMenuVisible:false,
            title:'',
            menu1Data:[],
            menu2Data:[],
            menu3Data:[],
            menu4Data:[],
            menu1SelectedIdx:null,
            menu2SelectedIdx:null,
            menu3SelectedIdx:null,
            menu4SelectedIdx:null,
            menu1SelectedData:null,
            menu2SelectedData:null,
            menu3SelectedData:null,
            menu4SelectedData:null,
        }
    },
    computed:{
        isMenu1Null() {
            return this.menu1Data.length == 1 && this.menu1Data[0].Name == '无'
        },
        isMenu2Null() {
            return this.menu2Data.length == 1 && this.menu2Data[0].Name == '无'
        },
        isMenu3Null() {
            return this.menu3Data.length == 1 && this.menu3Data[0].Name == '无'
        }
    },
    watch:{
    },
    created(){
        this.init()
    },
    mounted(){
        document.addEventListener("click", e => {
            let _con = $('.VersionCascader')
            if(!_con.is(e.target) && _con.has(e.target).length === 0){
                this.cascaderMenuVisible = false
            }
        })
    },
    methods:{
        init(){
            if(this.isValidArray(this.versionsValue)){
                this.menu1SelectedIdx = this.versionsValue[0]
                this.menu2SelectedIdx = this.versionsValue[1]
                this.menu3SelectedIdx = this.versionsValue[2]
                this.menu4SelectedIdx = this.versionsValue[3]
            }
            this.menu1Data = this.data
            if(!this.menu1SelectedIdx){
                this.menu1SelectedData = this.menu1Data[0]
                this.menu1SelectedIdx = this.menu1Data[0].Idx
                this.menu2Data = this.menu1Data[0].ChildList
            }else{
                this.menu1Data.forEach(element => {
                    if(element.Idx == this.menu1SelectedIdx){
                        this.menu1SelectedData = element
                        this.menu2Data = element.ChildList
                    }
                });
            }
            if(!this.menu2SelectedIdx){
                this.menu2SelectedData = this.menu2Data[0]
                this.menu2SelectedIdx = this.menu2Data[0].Idx
                this.menu3Data = this.menu2Data[0].ChildList
            }else{
                this.menu2Data.forEach(element => {
                    if(element.Idx == this.menu2SelectedIdx){
                        this.menu2SelectedData = element
                        this.menu3Data = element.ChildList
                    }
                });
            }
            if(!this.menu3SelectedIdx){
                this.menu3SelectedData = this.menu3Data[0]
                this.menu3SelectedIdx = this.menu3Data[0].Idx
                this.menu4Data = this.menu3Data[0].ChildList
            }else{
                this.menu3Data.forEach(element => {
                    if(element.Idx == this.menu3SelectedIdx){
                        this.menu3SelectedData = element
                        this.menu4Data = element.ChildList
                    }
                });
            }
            if(this.menu4Data) {
                if(!this.menu4SelectedIdx){
                        this.menu4SelectedIdx = this.menu4Data[0].Idx
                        this.menu4SelectedData = this.menu4Data[0]
                }else{
                    this.menu4Data.forEach(element => {
                        if(element.Idx == this.menu4SelectedIdx){
                            this.menu4SelectedData = element
                        }
                    });
                }
            }
            this.setTitle()
        },
        onMenu1Click(itemData){
            this.menu1SelectedData = itemData
            this.menu2SelectedData = this.menu3SelectedData = this.menu4SelectedData = null
            this.menu1SelectedIdx = itemData.Idx
            this.menu2Data = itemData.ChildList
            this.menu3Data = this.menu4Data = []
            this.menu2SelectedIdx = this.menu3SelectedIdx = this.menu4SelectedIdx = null
            if (this.isMenu2Null) this.onMenu2Click(this.menu2Data[0])
        },
        onMenu2Click(itemData){
            this.menu2SelectedData = itemData
            this.menu3SelectedData = this.menu4SelectedData = null
            this.menu2SelectedIdx = itemData.Idx
            this.menu3Data = itemData.ChildList
            this.menu4Data = []
            this.menu3SelectedIdx = this.menu4SelectedIdx =  null
            if (this.isMenu3Null) this.onMenu3Click(this.menu3Data[0])
        },
        onMenu3Click(itemData){
            this.menu3SelectedData = itemData
            this.menu4SelectedData = null
            this.menu3SelectedIdx = itemData.Idx
            this.menu4Data = itemData.ChildList
            this.menu4SelectedIdx = null
            if(itemData.ChildList && itemData.ChildList.length == 1){
                this.onMenu4Click(itemData.ChildList[0])
            }
            if(!itemData.ChildList) {
                this.$emit('change',[
                    this.menu1SelectedIdx,
                    this.menu2SelectedIdx,
                    this.menu3SelectedIdx
                ])
                this.cascaderMenuVisible = false;
                this.setTitle();
            }
        },
        onMenu4Click(itemData){
            this.menu4SelectedData = itemData
            this.menu4SelectedIdx = itemData.Idx

            this.$emit('change',[
                this.menu1SelectedIdx,
                this.menu2SelectedIdx,
                this.menu3SelectedIdx,
                this.menu4SelectedIdx
            ])
            this.cascaderMenuVisible = false
            this.setTitle()
        },
        setTitle(){
            if(this.menu1SelectedData && this.menu2SelectedData && this.menu3SelectedData && this.menu4SelectedData) {
                this.title = `${this.menu1SelectedData.Name} / ${this.menu2SelectedData.Name} / ${this.menu3SelectedData.Name} / ${this.menu4SelectedData.Name}`  
            } else if(this.menu1SelectedData && this.menu2SelectedData && this.menu3SelectedData){
                this.title = `${this.menu1SelectedData.Name} / ${this.menu2SelectedData.Name} / ${this.menu3SelectedData.Name}`  
            } else if(this.menu1SelectedData && this.menu2SelectedData) {
                this.title = `${this.menu1SelectedData.Name} / ${this.menu2SelectedData.Name}`  
            } else if(this.menu1SelectedData){
                this.title = `${this.menu1SelectedData.Name}`
            }
            this.title = this.title.split(" / ").filter(item => item != "无").join(" / ");
        }
    }
}
</script>

<style lang='less' scoped>
.VersionCascader{
    display: inline-block;
    position: relative;
    top: 1px;
    margin-right: 20px;
}
.el-icon-arrow-down{
    transition: transform .3s,-webkit-transform .3s;
    color:#C0C4CC;
}
.turnIcon{
    transform: rotateZ(180deg);
}
.content{
    display: flex;
    flex-direction: column;
}
.title-box{
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    width: 100%;
    border: 1px solid #DCDFE6;
    padding: 0 15px;
    cursor: pointer;
    i{
        margin-left: 10px;
    }
}
.title-box:hover{
    border-color: #C0C4CC;
}
.cascader-menu-box{
    user-select: none;
    margin: 5px 0;
    font-size: 14px;
    background: #FFF;
    border: 1px solid #E4E7ED;
    border-radius: 4px;
    box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.1);
    min-height: 200px;
    z-index: 2000;
    display: flex;
    width:fit-content;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    padding: 6px 0;
    position: absolute;
    top: 50px;
    .el-icon-caret-top{
        position: absolute;
        top: -9px;
        left: 21px;
        color: #FFF;
    }
    ul,li{
        list-style:none;
    }
    li{
        padding: 0 10px 0 0;
        height: 34px;
        line-height: 34px;
        cursor: pointer;
        margin-right: 4px;
        margin-left: 6px;
        border-bottom: 1px solid #EEEEEE;
        position: relative;
    }
    li:hover{
        background: rgba(34, 181, 173,0.1);
    }
    li span{
        padding: 0 24px 0 10px;
        display:inline-block;
        white-space: nowrap;
    }
    li i{
        position: absolute;
        right: 10px;
        top: 10px;
    }
    .menu1,.menu2,.menu3,.menu4{
        position: relative;
    }
    .menu{
        max-height: 300px;
        overflow-y: auto;
    }
    .menu::-webkit-scrollbar{
        width:4px;
        height:10px;
    }
    .menu::-webkit-scrollbar-track{
        background: #FFF;
        border-radius:2px;
    }
    .menu::-webkit-scrollbar-thumb{
        background: #d8d8d8;
        border-radius:2px;
    }
    .menu::-webkit-scrollbar-thumb:hover{
        background: #333;
    }
    .menu::-webkit-scrollbar-corner{
        background: #179a16;
    }
    .menu:not(:last-child){
        border-right: solid 1px #E4E7ED;
    }
    .menuActive{
        color: #22B5AD;
    }
}
</style>
